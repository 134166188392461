import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { CENTROS, CENTROS_CERRADOS } from "../../../utils/academia"
import css from './AcademiaSelector.module.css'

const AcademiaSelector = ({ 
  className='',
  name,
  label,
  showAll=false,
  showInactive=false,
  showNoOption=false,
  multiple=false,
  value,
  onChange 
})=> (
  <FormControl 
    className={`${className} ${css.main}`} 
    variant="standard"
    fullWidth
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      multiple={multiple}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {showNoOption && <MenuItem value=' '>Central (Sin centro)</MenuItem>}
      {showAll && <MenuItem value='TODOS'>Ver todos</MenuItem>}
      {CENTROS.map(centro=> <MenuItem key={centro} value={centro}>{centro}</MenuItem> )}
      {showInactive && CENTROS_CERRADOS.map(centro=> <MenuItem key={centro} value={centro}>{centro}</MenuItem> )}
    </Select>
  </FormControl>
)

export default AcademiaSelector