import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Card, CardMedia, Chip, Typography } from '@mui/material'
import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'
import PageLoading from '../../../components/PageLoading/PageLoading'
import { getAlumno, getFotoAlumno, updateFacturacionAlumno, updateFichaAlumno, updateTutoresAlumno } from '../../../utils/api/alumnos'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { dateFromUnixTimestamp, dateToStringLong } from '../../../utils/date'
import { getPerfilAcademico } from '../../../utils/model/alumnos'
import useAlumno from '../../../hooks/useAlumno'
import { getAuth } from '../../../utils/auth'
import DatosPersonales from './components/DatosPersonales/DatosPersonales'
import DatosTutores from './components/DatosTutores/DatosTutores'
import DatosConnect from './components/DatosConnect/DatosConnect'
import ModalEditarFichaAlumno from '../../../modals/ModalEditarFichaAlumno/ModalEditarFichaAlumno'
import ModalEditarTutoresAlumno from '../../../modals/ModalEditarTutoresAlumno/ModalEditarTutoresAlumno'
import DatosFacturacion from './components/DatosFacturacion/DatosFacturacion'
import ModalEditarFacturacionAlumno from '../../../modals/ModalEditarFacturacionAlumno/ModalEditarFacturacionAlumno'
import user from './profile.jpg'
import DatosLegal from './components/DatosLegal/DatosLegal'
import css from './Ficha.module.css'
import { downloadCloudDocument } from '../../../utils/documents'

const Ficha = ()=> {

  const userData = getAuth()
  const notification = useNotification()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id, nombre } = useAlumno()

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isTutorModalOpen, setTutorModalOpen] = useState(false)
  const [isFacturacionModalOpen, setFacturacionModalOpen] = useState(false)


  const { isLoading, data: fichaAlumno={} } = useQuery({
    queryKey: ['alumnos', id], 
    queryFn: () => getAlumno(id)
      .then(datos=> ({
        ...datos,
        fechaNacimiento: dateFromUnixTimestamp(datos.fechaNacimiento),
        fechaFinMatricula: dateFromUnixTimestamp(datos.fechaFinMatricula),
        perfilAcademico: getPerfilAcademico({
          curso: datos.cursoEstudios, 
          nivel: datos.nivelEstudios, 
          centroEstudios: datos.centroEstudios
        })
      }))
      .catch(err=> {
        notification.error({ title: 'Error obteniendo datos de alumno', content: err })
        navigate('/alumnos')
        return {}
      })
  })

  const { data: fotoAlumno = '' } = useQuery({
    queryKey: ['foto-alumno', id], 
    queryFn: () => getFotoAlumno({ alumno: id })
    .then(datos=> datos.foto)
    .catch(()=> null)
  })

  const { isPending: isAlumnoUpdating, mutate: updateAlumno } = useMutation({
    mutationFn: updateFichaAlumno,
    onSuccess: () => {
      notification.success({ title: 'Alumno actualizado', content: 'Datos modificados correctamente' })
      queryClient.invalidateQueries({ queryKey: ['alumnos', id] })
      setEditModalOpen(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando el alumno', content: err })
    },
  })

  const { isPending: isTutoresUpdating, mutate: updateTutores } = useMutation({
    mutationFn: updateTutoresAlumno,
    onSuccess: () => {
      notification.success({ title: 'Alumno actualizado', content: 'Tutores modificados correctamente' })
      queryClient.invalidateQueries({ queryKey: ['alumnos', id] })
      setTutorModalOpen(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando los tutores', content: err })
    },
  })

  const { isPending: isFacturacionUpdating, mutate: updateFacturacion } = useMutation({
    mutationFn: updateFacturacionAlumno,
    onSuccess: () => {
      notification.success({ title: 'Alumno actualizado', content: 'Datos de facturación actualizados' })
      queryClient.invalidateQueries({ queryKey: ['alumnos', id] })
      setFacturacionModalOpen(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando facturación', content: err })
    },
  })

  const handleUpdateFichaAlumno = (data)=> {
    if (isAlumnoUpdating) return
    updateAlumno(data)
  }

  const handleUpdateTutoresAlumno = (data)=> {
    if (isTutoresUpdating) return
    updateTutores(data)
  }

  const handleUpdateFacturacionAlumno = (data)=> {
    if (isFacturacionUpdating) return
    updateFacturacion({ id, ...data })
  }

  const handleDownloadCondiciones = ()=> {
    if (!hasCondiciones) return
    downloadCloudDocument(fichaAlumno.condicionesUrl, `condiciones-${id}.pdf`)
  }

  const handleDownloadRGPD = ()=> {
    if (!hasRGPD) return
    downloadCloudDocument(fichaAlumno.rgpdurl, `rgpd-${id}.pdf`)
  }

  const hasCondiciones = !!fichaAlumno.condicionesUrl;
  const hasRGPD = !!fichaAlumno.rgpdurl;

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <AlumnoHeader label='Ficha' />
        <div className={css.alumno}>
          <Card variant='outlined' className={css.summary}>
            <CardMedia
              className={css.image}
              component='img'
              image={fotoAlumno || user}
              alt={nombre}
            />
            <div className={css.content}>
              <Typography variant='h6' component='h2'>
                {nombre}
              </Typography>
              <Typography variant='body2' component='p'>
                {fichaAlumno.perfilAcademico}
              </Typography>
              <section className={css.section} data-section="matricula">
                <Typography variant='body2' component='p'>
                  Caducidad de la matrícula: <strong>{dateToStringLong(fichaAlumno.fechaFinMatricula)}</strong>
                </Typography>
              </section>
              <section className={css.section} data-section="lead">
                {fichaAlumno.leadId ? (
                  <Chip 
                    className={css.chip}
                    icon={<i className='material-icons'>face</i>} 
                    component={Link}
                    to={`/leads/${fichaAlumno.leadId}/ficha`}
                    label="Ver ficha lead" 
                    variant="outlined" 
                  />
                ) : (
                  <Typography variant='body2' component='p'>
                    No hay lead asociado a este alumno
                  </Typography>
                )}
              </section>
              <section className={css.section} data-section="documentacion">
                <Chip 
                  className={css.chip}
                  icon={<i className='material-icons'>{hasCondiciones ? 'check' : 'close'}</i>}
                  color={hasCondiciones ? 'primary' : 'error'}
                  onClick={handleDownloadCondiciones}
                  href={fichaAlumno.condicionesUrl}
                  label="Condiciones contratacion" 
                  variant="outlined" 
                />
                <Chip 
                  className={css.chip}
                  icon={<i className='material-icons'>{hasRGPD ? 'check' : 'close'}</i>} 
                  color={hasRGPD ? 'primary' : 'error'}
                  onClick={handleDownloadRGPD}
                  label="Aceptación RGPD" 
                  variant="outlined" 
                />
              </section>
              {userData.centro !== fichaAlumno.centro && (
                <section className={css.section} data-section="academia">
                  <Typography className={css.academy} variant='body2' component='p'>
                    <i className='material-icons'>school</i>
                    Alumno de <strong>{fichaAlumno.centro}</strong>
                  </Typography>
                </section>
              )}
            </div>
          </Card>
          <div className={css.ficha}>
            <div className={css.accordion}>
              <DatosPersonales
                alumno={fichaAlumno}
                onEdit={()=> setEditModalOpen(true)}
              />
            </div>
            <div className={css.accordion}>
              <DatosTutores
                tutor1={fichaAlumno.tutor1}
                tutor2={fichaAlumno.tutor2}
                onEdit={()=> setTutorModalOpen(true)}
              />
            </div>
            <div className={css.accordion}>
              <DatosFacturacion
                facturacion={fichaAlumno.facturacion}
                onEdit={()=> setFacturacionModalOpen(true)}
              />
            </div>
            <div className={css.accordion}>
              <DatosConnect alumno={fichaAlumno} />
            </div>
            <div className={css.accordion}>
              <DatosLegal alumno={fichaAlumno} />
            </div>
          </div>
          <ModalEditarFichaAlumno
            open={isEditModalOpen}
            alumno={fichaAlumno}
            onSubmit={handleUpdateFichaAlumno}
            onClose={()=> setEditModalOpen(false)}
          />
          <ModalEditarTutoresAlumno
            open={isTutorModalOpen}
            alumno={fichaAlumno}
            onSubmit={handleUpdateTutoresAlumno}
            onClose={()=> setTutorModalOpen(false)}
          />
          <ModalEditarFacturacionAlumno
            open={isFacturacionModalOpen}
            facturacion={fichaAlumno.facturacion}
            onSubmit={handleUpdateFacturacionAlumno}
            onClose={()=> setFacturacionModalOpen(false)}
          />
        </div>
      </div>
    </PageLoading>
  )
}

export default Ficha
