import { GET, POST, PUT, DELETE } from '../request'

export const getAsistenciaFormacionList = ({ fecha, hora }) => GET('/api/agora/planificacion/asistencia-formacion/list', { fecha, hora })

export const getAsistenciasFormacion = ({ formacion }) => GET('/api/agora/planificacion/asistencia-formacion/list/formacion', { formacion })

export const planificarAsistencias = ({ id, asistencias })=> POST('/api/agora/planificacion/asistencia-formacion/planning', {}, { id, asistencias })

export const updateAsistencia = ({ id, asignatura, centro, profesor, observaciones }) => PUT('/api/agora/planificacion/asistencia-formacion', { id, asignatura, centro, profesor, observaciones })

export const bulkUpdateProfesor = ({ asistencias, profesor })=> PUT('/api/agora/planificacion/asistencia-formacion/multiple-profesor', { 'asistencias[]': asistencias, profesor })

export const bulkUpdateCentro = ({ asistencias, centro })=> PUT('/api/agora/planificacion/asistencia-formacion/multiple-centro', { 'asistencias[]': asistencias, centro })

export const bulkUpdateAsignatura = ({ asistencias, asignatura })=> PUT('/api/agora/planificacion/asistencia-formacion/multiple-asignatura', { 'asistencias[]': asistencias, asignatura })

export const bulkDelete = ({ asistencias })=> DELETE('/api/agora/planificacion/asistencia-formacion/multiple', { 'asistencias[]': asistencias })