import { GET, POST, PUT, DELETE } from '../request'

export const getAsistenciasAlumno = ({ alumno, fecha, fechaFin }) => GET('/api/agora/planificacion/asistencia/list/alumno', { alumno, fecha, fecha_fin: fechaFin })

export const getAsistenciaTotals = ({ fecha }) => GET('/api/agora/planificacion/asistencia/list/totals', { fecha })

export const getAsistenciaList = ({ fecha, hora }) => GET('/api/agora/planificacion/asistencia/list', { fecha, hora })

export const getAsistenciaLeadList = ({ fecha, hora }) => GET('/api/agora/planificacion/asistencia/list/feedback', { fecha, hora })

export const getAsistenciasPendientes = () => GET('/api/agora/planificacion/asistencia/list/sin-asistencia')

export const getAsistenciasSinProfesor = (mes) => GET('/api/agora/planificacion/asistencia/list/sin-profesor', { mes})

export const getAsistenciasContratacion = (contratacion, id)=> {
  if (contratacion === 'tarifa') return getAsistenciasTarifa(id)
  if (contratacion === 'intensivo') return getAsistenciasIntensivo(id)
  if (contratacion === 'pack') return getAsistenciasPackHoras(id)
}

export const getAsistenciasTarifa = (tarifaId)=> GET('/api/agora/planificacion/asistencia/list/tarifa', { tarifa: tarifaId })

export const getAsistenciasIntensivo = (intensivoId)=> GET('/api/agora/planificacion/asistencia/list/intensivo', { intensivo: intensivoId })

export const getAsistenciasPackHoras = (packId)=> GET('/api/agora/planificacion/asistencia/list/pack', { pack: packId })

export const updateAsistencia = ({ id, asistencia, asignatura, centro, profesor, observaciones, multiple=false }) => PUT('/api/agora/planificacion/asistencia', { id, asistencia, asignatura, centro, profesor, observaciones, multiple })

export const planificarAsistencias = ({ alumno, profesor, clase, centro, tipoContratacion, contratacion, asignatura, asistencias, observaciones })=> POST('/api/agora/planificacion/asistencia/planning', {}, { alumno, profesor, clase, centro, tipoContratacion, contratacion, asignatura, asistencias, observaciones })

export const updateAsistenciaLead = ({ id, fecha, hora, asistencia, profesor, observaciones }) =>  PUT('/api/agora/planificacion/asistencia/feedback', { id, fecha, hora, asistencia, profesor, observaciones })

export const bulkUpdateProfesor = ({ asistencias, profesor })=> PUT('/api/agora/planificacion/asistencia/multiple-profesor', { 'asistencias[]': asistencias, profesor })

export const bulkUpdateCentro = ({ asistencias, centro })=> PUT('/api/agora/planificacion/asistencia/multiple-centro', { 'asistencias[]': asistencias, centro })

export const bulkUpdateAsignatura = ({ asistencias, asignatura })=> PUT('/api/agora/planificacion/asistencia/multiple-asignatura', { 'asistencias[]': asistencias, asignatura })

export const bulkDelete = ({ asistencias })=> DELETE('/api/agora/planificacion/asistencia/multiple', { 'asistencias[]': asistencias })