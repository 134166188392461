
import { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from "@mui/material"
import QRCode from "../../../../../components/QRCode/QRCode"
import { getConnectURL } from "../../../../../utils/documents"
import { copyToClipboard } from "../../../../../utils/clipboard"
import css from "./DatosConnect.module.css"

const DatosConnect = ({ alumno }) => {

  const [isExpanded, setExpanded] = useState(false)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const url = getConnectURL(alumno)

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Connect</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.info}>
          <Typography className={css.text} variant="body2">
            Deja que {alumno.nombre} escanee este código QR para acceder a la plataforma Connect.
            <br />
            Desde la plataforma podrá valorar su experiencia con <strong>mundo</strong>estudiante.
          </Typography>
          <div className={css.buttons}>
            <IconButton 
              className={css.button} 
              color='primary'
              onClick={()=> copyToClipboard(url)}
            >
              <i className='material-icons'>content_paste_go</i>
            </IconButton>
          </div>
        </div>
        <div className={css.code}>
          <QRCode url={url} />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosConnect