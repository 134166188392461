import { GET, POST, PUT } from '../request'

export const getLead = id=> GET('/api/agora/marketing/feedback', { id })

export const getCRM = (centro)=> GET('/api/agora/marketing/feedback/list/CRM', { centro })

export const getOpenLeads = ()=> GET('/api/agora/marketing/feedback/list/open')

export const getFeedbackListYear = (periodo)=> GET(`/api/agora/marketing/feedback/list/year`, { periodo })

export const getPruebas = ({ fecha, centro }) => GET('/api/agora/marketing/feedback/list/pruebas', { fecha, centro })

export const verificarLead = ({ 
  email_contacto, 
  telefono_contacto, 
  email_alumno, 
  telefono_alumno 
})=> GET('/api/agora/marketing/feedback/verificar-lead', { 
  email_contacto, 
  telefono_contacto, 
  email_alumno, 
  telefono_alumno 
})

export const crearLead = ({
  contacto,
  nombre_contacto,
  email_contacto,
  telefono_contacto,
  nombre_alumno,
  email_alumno,
  telefono_alumno,
  centro_estudios,
  asignaturas,
  tipologia_academica,
  curso,
  objetivos,
  como_conoce,
  forma_contacto,
  centro,
  codigo_postal,
  lead_status,
  fecha_lead,
  hora_lead,
  centro_prueba,
  fecha_prueba,
  hora_prueba,
  asignatura_prueba,
  profesor_prueba,
  causa_perdido,
  telefono_entrante,
  fecha_contacto,
  observaciones,
})=> POST('/api/agora/marketing/feedback', {
  contacto,
  nombre_contacto,
  email_contacto,
  telefono_contacto,
  nombre_alumno,
  email_alumno,
  telefono_alumno,
  centro_estudios,
  'asignaturas[]': asignaturas,
  tipologia_academica,
  curso,
  objetivos,
  como_conoce,
  forma_contacto,
  centro,
  codigo_postal,
  lead_status,
  fecha_lead,
  hora_lead,
  centro_prueba,
  fecha_prueba,
  hora_prueba,
  asignatura_prueba,
  profesor_prueba,
  causa_perdido,
  telefono_entrante,
  fecha_contacto,
  observaciones,
})

export const updateLead = ({
  id,
  contacto,
  nombreContacto,
  emailContacto,
  telefonoContacto,
  nombreAlumno,
  emailAlumno,
  telefonoAlumno,
  centroEstudios,
  asignaturas,
  tipologiaAcademica,
  formaContacto,
  curso,
  objetivos,
  comoConoce,
  centro,
  telefonoEntrante,
})=> PUT('/api/agora/marketing/feedback', {
  id,
  contacto,
  nombreContacto,
  emailContacto,
  telefonoContacto,
  nombreAlumno,
  emailAlumno,
  telefonoAlumno,
  centroEstudios,
  'asignaturas[]': asignaturas,
  tipologiaAcademica,
  formaContacto,
  curso,
  objetivos,
  comoConoce,
  centro,
  telefonoEntrante,
})

export const addContacto = ({
  id,
  lead_status,
  forma_contacto_lead,
  fecha_lead,
  hora_lead,
  centro_prueba,
  fecha_prueba,
  hora_prueba,
  asignatura_prueba,
  fecha_lead_cualificado,
  hora_lead_cualificado,
  presupuesto_lead_cualificado,
  fecha_incorporacion_lead_cualificado,
  causa_perdido,
  alumno_captado,
  observaciones
})=> {

  if (lead_status === 'LEAD') {
    return PUT('/api/agora/marketing/feedback/nuevo-contacto', {
      id,
      lead_status,
      forma_contacto_lead,
      fecha_lead,
      hora_lead,
      observaciones
    })
  }
  if (lead_status === 'PRUEBA') {
    return PUT('/api/agora/marketing/feedback/nuevo-contacto', {
      id,
      lead_status,
      centro_prueba,
      fecha_prueba,
      hora_prueba,
      asignatura_prueba,
      observaciones
    })
  }
  if (lead_status === 'LEAD_CUALIFICADO') {
    return PUT('/api/agora/marketing/feedback/nuevo-contacto', {
      id,
      lead_status,
      fecha_lead_cualificado,
      hora_lead_cualificado,
      presupuesto_lead_cualificado,
      fecha_incorporacion_lead_cualificado,
      observaciones
    })
  }
  if (lead_status === 'CLIENTE_CAPTADO') {
    return PUT('/api/agora/marketing/feedback/nuevo-contacto', {
      id,
      lead_status,
      alumno_captado,
      observaciones
    })
  }
  if (lead_status === 'CLIENTE_PERDIDO') {
    return PUT('/api/agora/marketing/feedback/nuevo-contacto', {
      id,
      lead_status,
      causa_perdido,
      observaciones
    })
  }
  if (lead_status === 'LEAD_NO_VALIDO') {
    return PUT('/api/agora/marketing/feedback/nuevo-contacto', {
      id,
      lead_status,
      observaciones
    })
  }
  else return Promise.reject('Error en el estado del lead')
}

export const modificarPrueba = ({
  id,
  fecha,
  hora,
  asignatura,
  asistencia,
  profesor,
  confirmacion
})=> PUT('/api/agora/marketing/feedback/modificar-prueba', {
  id,
  fecha,
  hora,
  asignatura,
  asistencia,
  profesor,
  confirmacion
})
