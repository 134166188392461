import { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import css from "./DatosAcceso.module.css"

const DatosAcceso = ({ accesoFormacion, onEdit, onRestoreAccess }) => {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => setExpanded(isExpanded)
  
  const handleEdit = (e)=> {
    e.stopPropagation()
    onEdit()
  }

  const handleVerifyEmail = (e) => {
    e.stopPropagation()
    onRestoreAccess() 
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Email de acceso a Classfy
          </Typography>
          {isExpanded && (
            <IconButton
              className={css.button}
              size="small"
              color="secondary"
              onClick={handleEdit}
            >
              <i className="material-icons">edit</i>
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.info}>
          {!accesoFormacion.activo && (
            <Alert 
              icon={<i className='material-icons'>warning</i>} 
              className={css.alert} 
              severity='error'
            >
              Este acceso ha sido desactivado
            </Alert>
          )}
          <TextField
            label="Email"
            value={accesoFormacion.email} 
            variant='standard'
          />
          <FormControlLabel
            control={(<Checkbox checked={accesoFormacion.emailVerificado} />)} 
            label='Email verificado'
          />
          {!accesoFormacion.emailVerificado && (
            <Button className={css.password} onClick={handleVerifyEmail} variant="text">
              <i className='material-icons'>email</i> 
              <span className={css.email}>Enviar email de verificación</span>
            </Button> 
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosAcceso
