import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Card, Fab } from "@mui/material"
import { Link } from "react-router-dom"
import PageLoading from "../../components/PageLoading/PageLoading"
import TableHeader from "../../components/TableHeader/TableHeader"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import ModalContactoLead from "../../modals/ModalContactoLead/ModalContactoLead"
import ModalContactoLeadCerrado from "../../modals/ModalContactoLeadCerrado/ModalContactoLeadCerrado"
import ModalContactoLeadCualificado from "../../modals/ModalContactoLeadCualificado/ModalContactoLeadCualificado"
import ModalContactoPrueba from "../../modals/ModalContactoPrueba/ModalContactoPrueba"
import { addContacto, getCRM } from "../../utils/api/leads"
import { getAuth } from "../../utils/auth"
import { dateFromUnixTimestamp, getDateRelative } from "../../utils/date"
import { getEstadoContacto } from "../../utils/model/leads"
import { textFilter } from "../../utils/table"
import AcademiaPermitidaSelector from "../../components/form/AcademiaPermitidaSelector/AcademiaPermitidaSelector"
import css from './CRM.module.css'


const CRMColumn = ({ title, isActive, onDragOver, onDragLeave, onDrop, children })=> (
  <div 
    className={css.column}
    data-status={isActive ? 'active' : 'inactive'}
    onDragOver={(event)=> {
      event.preventDefault()
      onDragOver(title)
    }}
    onDragLeave={()=> onDragLeave(title)}
    onDrop={(event)=> {
      event.preventDefault()
      onDrop(title)
    }}
  >
    <h2 className={css.columnTitle}>{title}</h2>
    <div className={css.columnContent}>
      {children}
    </div>
  </div>
)

const CRMCard = ({ lead, centro, onDragStart })=> {
  const { id, fecha, relacion, alumno, contacto, telefono, fechaRelativa, estado, pruebaConfirmada, pruebaAutomatica, centroPrueba } = lead
  const isRaised = estado !== 'CLIENTE_CAPTADO'
  const status = getEstadoContacto(dateFromUnixTimestamp(fecha))
  const isPropioAlumno = relacion && relacion.toUpperCase().includes('ALUMNO')
  return (
    <Card 
      draggable 
      raised={isRaised} 
      className={css.card}
      data-status={status}
      data-stage={estado}
      onDragStart={()=> onDragStart(lead)}
    >
      <Link className={css.cardTitle} to={`/leads/${id}/ficha`}>
        {alumno ? `${alumno}` : <span className={css.placeholder}>(Lead sin nombre)</span>}
      </Link>
      {!isPropioAlumno && (
        <p className={css.cardRow}>
          <i className='material-icons'>person</i>
          <span className={css.cardInfo}>{contacto}</span>
          {relacion && <span className={css.cardInfo}>{' '}({relacion})</span>}
        </p>
      )}
      {telefono && (
        <p className={css.cardRow}>
          <i className='material-icons'>phone</i>
          <span className={css.cardInfo}>{telefono}</span>
        </p>
      )}
      {fechaRelativa && (
        <p className={css.cardRow}>
          <i className='material-icons'>calendar_today</i>
          <span className={css.cardInfo}>{fechaRelativa}</span>
        </p>
      )}
      {estado === 'PRUEBA' && !pruebaConfirmada && (
        <p className={css.cardRow} data-status="danger">
          <i className='material-icons'>dangerous</i>
          <span className={css.cardInfo}>Prueba no confirmada</span>
        </p>
      )}
      {estado === 'PRUEBA' && pruebaAutomatica && (
        <p className={css.cardRow} data-status="warning">
          <i className='material-icons'>warning</i>
          <span className={css.cardInfo}>Prueba automática</span>
        </p>
      )}
      {estado === 'PRUEBA' && centroPrueba !== centro && (
        <p className={css.cardRow}>
          <i className='material-icons'>school</i>
          <span className={css.cardInfo}>Prueba en <strong>{centroPrueba}</strong></span> 
        </p>
      )}
    </Card>
  )
}

const CRM = ()=> {

  const userData = getAuth()

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [centro, setCentro] = useState(userData.centro || ' ')
  const [search, setSearch] = useState('')

  const [activeLead, setActiveLead] = useState(null)
  const [activeColumn, setActiveColumn] = useState(null)
  const [dropColumn, setDropColumn] = useState(null)

  const { 
    isLoading, 
    data: crmColumns={
      leads_nuevos: [],
      leads_prueba: [],
      leads_cualificados: [],
      leads_captados: [],
      leads_perdidos: [],
      leads_no_validos: [],
    } 
  } = useQuery({
    queryKey: ['marketing', 'feedback', 'list', 'CRM', centro], 
    queryFn: ()=> getCRM(centro.trim())
      .then(leads=> leads.sort((l1, l2)=> l1.fecha - l2.fecha))
      .then(leads=> {
        const columns = {
          leads_nuevos: [],
          leads_prueba: [],
          leads_cualificados: [],
          leads_captados: [],
          leads_perdidos: [],
          leads_no_validos: [],
        }
        // Clasificamos el lead por su estado
        leads
          .map(lead=> ({
            ...lead,
            fechaRelativa: getDateRelative(lead.fecha),
          }))
          .forEach(lead=> {
            if (lead.estado === 'LEAD') columns.leads_nuevos.push(lead)
            else if (lead.estado === 'PRUEBA') columns.leads_prueba.push(lead)
            else if (lead.estado === 'LEAD_CUALIFICADO') columns.leads_cualificados.push(lead)
            else if (lead.estado === 'CLIENTE_CAPTADO') columns.leads_captados.push(lead)
            else if (lead.estado === 'CLIENTE_PERDIDO') columns.leads_perdidos.push(lead)
            else if (lead.estado === 'LEAD_NO_VALIDO') columns.leads_no_validos.push(lead)
          })
        return columns
      })
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los datos del CRM', content: err })
        return {
          leads_nuevos: [],
          leads_prueba: [],
          leads_cualificados: [],
          leads_captados: [],
          leads_perdidos: [],
          leads_no_validos: [],
        }
      })
  })

  const { isPending: isLeadUpdating, mutate: updateLead } = useMutation({
    mutationFn: addContacto,
    onSuccess: () => {
      notification.success({ title: 'Lead actualizado', content: 'Contacto añadido correctamente' })
      queryClient.invalidateQueries({
        queryKey: ['marketing', 'feedback', 'list', 'CRM']
      })
      setDropColumn(null)
      setActiveLead(null)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando el lead', content: err })
    },
  })

  const filterSearch = lead=> {
    return textFilter({ 
      object: lead, 
      fields: ['contacto', 'telefono', 'email', 'fechaRelativa'],
      search: search
    })
  }

  const handleDragOver = (column)=> {
    if (activeColumn !== column) setActiveColumn(column)
  }

  const handleDragLeave = (column)=> {
    if (activeColumn === column) setActiveColumn(null)
  }

  const handleDrop = (column)=> {
    setActiveColumn(null)
    setDropColumn(column)
  }

  const handleDragStart = (lead)=> {
    setActiveLead(lead)
  }

  const handleModalClose = ()=> {
    setDropColumn(null)
    setActiveLead(null)
  }

  const handleSubmitContacto = (contacto)=> {
    if (!activeLead || isLeadUpdating) return
    updateLead({ 
      id: activeLead.id,
      centro_contacto: centro,
      ...contacto 
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaPermitidaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
          </div>
        )}
        title='CRM'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <div className={css.crm}>
        <CRMColumn
          title='Nuevos Leads'
          isActive={activeColumn === 'Nuevos Leads'}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {crmColumns.leads_nuevos
            .filter(filterSearch)
            .map(lead=> (
              <CRMCard 
                key={lead.id} 
                lead={lead} 
                centro={userData.centro}
                onDragStart={handleDragStart}
              />
            ))}
        </CRMColumn>
        <CRMColumn
          title='Pruebas'
          isActive={activeColumn === 'Pruebas'}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {crmColumns.leads_prueba
            .filter(filterSearch)
            .map(lead=> (
              <CRMCard 
                key={lead.id} 
                lead={lead} 
                onDragStart={handleDragStart}
              />
            ))}
        </CRMColumn>
        <CRMColumn
          title='Leads Cualificados'
          isActive={activeColumn === 'Leads Cualificados'}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {crmColumns.leads_cualificados
            .filter(filterSearch)
            .map(lead=> (
              <CRMCard 
                key={lead.id} 
                lead={lead} 
                onDragStart={handleDragStart}
              />
            ))}
        </CRMColumn>
        <CRMColumn
          title='Leads Cerrados'
          isActive={activeColumn === 'Leads Cerrados'}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {crmColumns.leads_captados
            .filter(filterSearch)
            .map(lead=> (
              <CRMCard 
                key={lead.id} 
                lead={lead} 
                onDragStart={handleDragStart}
              />
            ))}
          {crmColumns.leads_perdidos
            .filter(filterSearch)
            .map(lead=> (
              <CRMCard 
                key={lead.id} 
                lead={lead} 
                onDragStart={handleDragStart}
              />
            ))}
          {crmColumns.leads_no_validos
            .filter(filterSearch)
            .map(lead=> (
              <CRMCard 
                key={lead.id} 
                lead={lead} 
                onDragStart={handleDragStart}
              />
            ))}
        </CRMColumn>
      </div>
      <Fab
        className={css.fab}
        color='primary'
        aria-label='add'
        href='/nuevo-lead'
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalContactoLead
        open={dropColumn === 'Nuevos Leads'}
        disabled={isLeadUpdating}
        onClose={handleModalClose}
        onSubmit={handleSubmitContacto}
      />
      <ModalContactoPrueba
        open={dropColumn === 'Pruebas'}
        disabled={isLeadUpdating}
        onClose={handleModalClose}
        onSubmit={handleSubmitContacto}
      />
      <ModalContactoLeadCualificado
        open={dropColumn === 'Leads Cualificados'}
        disabled={isLeadUpdating}
        onClose={handleModalClose}
        onSubmit={handleSubmitContacto}
      />
      <ModalContactoLeadCerrado
        open={dropColumn === 'Leads Cerrados'}
        disabled={isLeadUpdating}
        onClose={handleModalClose}
        onSubmit={handleSubmitContacto}
      />
    </PageLoading>
  )
}

export default CRM
