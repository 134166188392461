import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Card, Typography } from "@mui/material"
import PageLoading from "../../../components/PageLoading/PageLoading"
import MesRecienteSelector from "../../../components/form/MesRecienteSelector/MesRecienteSelector"
import ModalGaleriaCliente from "../../../modals/ModalGaleriaCliente/ModalGaleriaCliente"
import { dateToString, dateToTimeString, monthToString } from "../../../utils/date"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import useFormacion from "../../../hooks/useFormacion"
import { getGaleria } from "../../../utils/api/imagenesSubidas"
import FormacionHeader from "../../../components/FormacionHeader/FormacionHeader"
import css from "./Galeria.module.css"

const Galeria = () => {

  const [mes, setMes] = useState(monthToString(new Date()))
  const [imagen, setImagen] = useState(null)

  const { id } = useFormacion()
  const notification = useNotification()

  const { isLoading, data: galeriaAlumno = [] } = useQuery({
    queryKey: ["classfy", "imagen-subida", "list", id, mes],
    queryFn: () =>
      getGaleria({ cliente: id, mes })
        .then((response) => {
          const sortedData = response.sort((a, b) => b.fecha - a.fecha)
          return sortedData
        })
        .catch((err) => {
          notification.error({
            title: "Error al recuperar las imágenes",
            content: err,
          })
          return []
        }),
    enabled: !!mes,
  })

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <FormacionHeader label="Galería" />
        <MesRecienteSelector
          className={css.mes}
          name="mes"
          label="Mes"
          forwardOptions={0}
          backwardOptions={4}
          value={mes}
          onChange={(e) => { setMes(e.target.value) }}
        />
        <div className={css.content}>
          {galeriaAlumno.length === 0 ? (
            <Typography className={css.text}>
              No hay imágenes disponibles.
            </Typography>
          ) : (
            <div className={css.list}>
              {galeriaAlumno.map((imagen) => (
                <Card 
                  key={imagen.id} className={css.item} 
                  onClick={() => setImagen(imagen)}
                >
                  <img
                    className={css.img}
                    src={imagen.thumbnail}
                    alt="Imagen formación"
                  />
                  <div className={css.info}>
                    <p className={css.date}>
                      <i className='material-icons'>event</i>
                      {dateToString(imagen.fecha)}
                    </p>
                    <p className={css.date}>
                      <i className='material-icons'>schedule</i>
                      {dateToTimeString(imagen.fecha)}
                    </p>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </div>
        <ModalGaleriaCliente
          open={!!imagen}
          url={imagen?.url}
          onClose={() => setImagen(null)}
        />
      </div>
    </PageLoading>
  )
}

export default Galeria
