
import { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Alert, IconButton, Typography } from "@mui/material"
import QRCode from "../../../../../components/QRCode/QRCode"
import { copyToClipboard } from "../../../../../utils/clipboard"
import { useNotification } from "../../../../../context/NotificationManager/NotificationManager"
import { useQuery } from "@tanstack/react-query"
import { getTokenIdentificacion } from "../../../../../utils/api/alumnos"
import css from './DatosLegal.module.css'
import { generateContratoURL } from "../../../../../utils/documents"

const DatosLegal = ({ alumno })=> {

  const [isExpanded, setExpanded] = useState(false)
  
  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const notification = useNotification()

  const { data: token='' } = useQuery({
    queryKey: ['alumnos', 'alumno', 'identification-token'], 
    queryFn: ()=> getTokenIdentificacion(alumno.id)
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar tu perfil', content: err })
        return ''
      })
  })

  const hasCondiciones = !!alumno.condicionesUrl;
  const hasRGPD = !!alumno.rgpdurl;

  const isComplete = hasCondiciones && hasRGPD;

  const url = generateContratoURL({ token })

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Condiciones de contratación</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isComplete ? (
          <Alert className={css.alert} severity='info'>
            El alumno ya ha firmado las condiciones de contratación y RDPD. 
            No es necesario que vuelva a firmarlas.
          </Alert>
        ) : (
          <>
            <div className={css.info}>
              <Typography className={css.text} variant="body2">
                Deja que {alumno.nombre} escanee este código QR para firmar las condiciones de 
                contratación y la aceptación de la RGPD.
              </Typography>
              <div className={css.buttons}>
                <IconButton 
                  className={css.button}
                  disabled={!token}
                  color='primary'
                  onClick={()=> copyToClipboard(url)}
                  >
                  <i className='material-icons'>content_paste_go</i>
                </IconButton>
              </div>
            </div>
            <div className={css.code}>
              <QRCode url={url} />
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosLegal