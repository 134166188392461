export const canRequestProfesores = (user)=> {
  if (!user || !user.rol) return false
  if (user.rol === 'USER') return false
  if (user.rol === 'MARKETING') return false
  return true
}

export const canAccessCentral = (user)=> {
  if (!user || !user.rol) return false
  if (user.rol === 'USER') return false
  if (user.rol === 'COORDINADOR') return false
  return true
}