import { useQuery } from "@tanstack/react-query"
import { Link } from "@mui/material"
import AlumnoHeader from "../../../components/AlumnoHeader/AlumnoHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import InformationTable from "../../../components/Table/InformationTable"
import MesRecienteSelector from "../../../components/form/MesRecienteSelector/MesRecienteSelector"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getCuadernillos } from "../../../utils/api/cuadernillos"
import { useState } from "react"
import useAlumno from "../../../hooks/useAlumno"
import { dateTimeToString, monthToString } from "../../../utils/date"
import css from "./Cuadernillos.module.css"

const Cuadernillos = () => {
  const notification = useNotification()
  const { id } = useAlumno()

  const [mes, setMes] = useState(monthToString(new Date()))

  const { isLoading, data: cuadernilloList = [] } = useQuery({
    queryKey: ["classfy", "seleccion-cuadernillo", "list", id, mes],
    queryFn: () => getCuadernillos({ cliente: id, mes })
      .then((response) => response.sort((a, b) => b.fecha - a.fecha))
      .catch((err) => {
        notification.error({ title: "Error al recuperar los cuadernillos", content: err })
        return []
      }),
  })

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <AlumnoHeader label="Cuadernillos" />
        <MesRecienteSelector
          className={css.mes}
          name="mes"
          label="Mes"
          forwardOptions={0}
          backwardOptions={5}
          value={mes}
          onChange={(e) => setMes(e.target.value)}
        />
        <InformationTable
          details={[
            { title: "Fecha", key: "fecha" },
            { title: "Profesor", key: "profesor"},
            { title: "Cuadernillo", key: "cuadernillo", cell: css.titulo }
          ]}
          data={cuadernilloList.map((cuadernillo) => ({
            fecha: dateTimeToString(cuadernillo.fecha) || "--",
            profesor: cuadernillo.profesorNombre || "--",
            cuadernillo: (
              <Link className={css.link} href={cuadernillo.url} target="_blank">
                {cuadernillo.titulo || '--'}
              </Link>
            ),
          }))}
        />
      </div>
    </PageLoading>
  )
}

export default Cuadernillos
