export const downloadCloudDocument = async (url, filename)=> {
  // Fetch the file
  const response = await fetch(url);
  if (!response.ok) return

  // Create a blob from the response
  const blob = await response.blob();

  // Create a temporary link element
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename; // Suggested filename

  // Append the link, trigger the download, and remove it
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Revoke the object URL after download
  URL.revokeObjectURL(a.href);
}

export const generateContratoURL = ({ token })=> {
  return 'https://condiciones.mundoestudiante.cloud/contrato?token=' + token
}


export const getConnectURL = ({ id })=> {
  const baseURL = `https://connect.mundoestudiante.cloud`
  return `${baseURL}/cuestionario/${id}`
}

export const getClassfyAdminURL = ({ id })=> {
  return `https://admin.classfy.online/students/${id}`
}

export const generatePDFURL = documentURL=> {
  const encodedURL = encodeURIComponent(documentURL)
  return `https://pdf.mundoestudiante.cloud/?url=${encodedURL}`
}

export const generatePDFRequest = ({ url, body })=> {
  const encodedURL = encodeURIComponent(url)
  return fetch(`https://pdf.mundoestudiante.cloud/?url=${encodedURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(res=> res.blob())
    .then(blob=> {
      const dataUrl = URL.createObjectURL(blob)
      window.open(dataUrl, '_blank')
    })
}

export const getCondicionesContratacionURL = ({ alumno, academia })=> {
  const document = `https://documents.mundoestudiante.cloud/condiciones-contratacion?academia=${academia}&alumno=${alumno}`
  return generatePDFURL(document)
}

export const getDocumentoRGPD = ({ alumno, academia })=> {
  const document = `https://documents.mundoestudiante.cloud/condiciones-rgpd?academia=${academia}&alumno=${alumno}`
  return generatePDFURL(document)
}

export const getBienvenidaAlumno = ({ alumno, academia })=> {
  const document = `https://documents.mundoestudiante.cloud/bienvenida-alumno?academia=${academia}&alumno=${alumno}`
  return generatePDFURL(document)
}

export const getPresupuesto = ({ academia, mes, alumno, matricula, tarifa_nombre, tarifa_precio, tarifa_condiciones, tarifa_base_precio, promocion, promocion_precio, promocion_descripcion })=> {
  const baseURL = `https://documents.mundoestudiante.cloud/presupuesto`
  // El símbolo de % no se manda bien, utilizamos "♦"
  const params = new URLSearchParams({
    academia,
    mes,
    alumno,
    matricula,
    tarifa_nombre,
    tarifa_precio,
    tarifa_condiciones: tarifa_condiciones.join(';').replaceAll('%', '♦'),
    tarifa_base_precio,
    promocion,
    promocion_precio,
    promocion_descripcion: promocion_descripcion.replaceAll('%', '♦')
  })
  return generatePDFURL(`${baseURL}?${params}`)
}

export const getCertificadoAlumno = ({ academia, fecha, alumno, formacion, fecha_inicio, fecha_fin, curso_academico, pagador, identificacion_pagador, desembolso })=> {
  const baseURL = `https://documents.mundoestudiante.cloud/certificado-alumno`
  const params = new URLSearchParams({
    academia,
    fecha,
    alumno,
    formacion,
    fecha_inicio,
    fecha_fin,
    curso_academico,
    pagador,
    identificacion_pagador,
    desembolso
  })
  return generatePDFURL(`${baseURL}?${params}`)
}

export const getJustificantePago = ({ academia, nombre, alumno, referencia, mes, fecha_emision, importe })=> {
  const baseURL = `https://documents.mundoestudiante.cloud/justificante-pago`
  const params = new URLSearchParams({
    academia,
    nombre,
    alumno,
    referencia,
    mes,
    fecha_emision,
    importe
  })
  return generatePDFURL(`${baseURL}?${params}`)
}

export const getFactura = ({ academia, numero_factura, cliente, nif, referencia, fecha_emision, concepto, importe })=> {
  const baseURL = `https://documents.mundoestudiante.cloud/factura`
  const params = new URLSearchParams({
    academia,
    numero_factura,
    cliente,
    nif,
    referencia,
    fecha_emision,
    concepto,
    importe
  })
  return generatePDFURL(`${baseURL}?${params}`)
}

export const getReservaPlaza = ({ academia })=> {
  const document = `https://documents.mundoestudiante.cloud/reserva-plaza?academia=${academia}`
  return generatePDFURL(document)
}

export const getPlanificacionClases = ({ academia, mes, alumno, asistencias })=> {
  const baseURL = `https://documents.mundoestudiante.cloud/planificacion-clases`
  const body = {
    academia,
    mes,
    alumno,
    asistencias
  }
  return generatePDFRequest({ url: baseURL, body })
}