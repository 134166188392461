import { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material"
import css from './Accesos.module.css'


const Accesos = ({ acceso, onEdit }) => {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }
  
  const handleEdit = (e)=> {
    e.stopPropagation()
    onEdit()
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
    <AccordionSummary
      expandIcon={<i className="material-icons">expand_more</i>}
    >
      <div className={css.label}>
        <Typography className={css.title} variant="h2">
          Accesos
        </Typography>
        {isExpanded && (
          <IconButton
            className={css.button}
            size="small"
            color="secondary"
            onClick={handleEdit}
          >
            <i className="material-icons">edit</i>
          </IconButton>
        )}
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <div className={css.checks}>
        <FormControlLabel
          control={(<Checkbox checked={acceso.accesoClassfy} /> )} 
          label={(
            <>
              <strong>Classfy</strong> - La persona en formación podrá iniciar sesión en Classfy.
            </>
          )}
        />
         <FormControlLabel
          control={(<Checkbox checked={acceso.accesoApp} /> )} 
          label={(
            <>
              <strong>App</strong> - La persona en formación podrá iniciar sesión en la app de mundoestudiante.
            </>
          )}
        />
        <FormControlLabel
          control={(<Checkbox checked={acceso.accesoBarbeibot} /> )} 
          label={(
            <>
              <strong>Barbeibot</strong> - La persona en formación podrá conversar con la IA de mundoestudiante.
            </>
          )}
        />
      </div>
    </AccordionDetails>
  </Accordion>
  )
}

export default Accesos
