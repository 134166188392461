import { useParams } from "react-router-dom";
import { getFormacion } from "../utils/api/formaciones";
import { useQuery } from "@tanstack/react-query";
import { useNotification } from "../context/NotificationManager/NotificationManager";
import { getAsistenciasFormacion } from "../utils/api/asistenciasFormacion";
import { dateToString } from "../utils/date";

const useFormacion = () => {

  const notification = useNotification()
  const { id } = useParams();

  const { isLoading: isLoadingDatos, data: formacion = {} } = useQuery({
    queryKey: ['formacion', 'formacion', id],
    enabled: !!id,
    queryFn: () => getFormacion(id)
      .then(datos => datos)
      .catch(err => {
        notification.error({ title: 'Error al recuperar los datos de la formación', content: err })
        return {}
      })
  })

  const { isLoading: isLoadingAsistencias, data: asistencias = [] } = useQuery({
    queryKey: ['planificacion', 'asistencia-formacion', 'list', 'formacion', id],
    enabled: !!id,
    queryFn: () => getAsistenciasFormacion({ formacion: id})
      .then(datos=> datos
        .sort((a, b)=> {
          if (a.fecha < b.fecha) return -1
          if (a.fecha > b.fecha) return 1
          if (a.hora < b.hora) return -1
          if (a.hora > b.hora) return 1
          return 0
        })
        .map(asistencia=> ({
          ...asistencia,
          fecha: dateToString(asistencia.fecha)
        })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias de la formacion', content: err })
        return []
      })
  })

  return {
    isLoading: isLoadingDatos || isLoadingAsistencias,
    id,
    nombre: formacion ? formacion.nombre : '',
    formacion,
    asistencias,
  }
}

export default useFormacion
