import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getUsuario } from "../../../utils/api/usuarios"
import { canAccessCentral } from "../../../utils/security"
import css from './AcademiaPermitidaSelector.module.css'

const AcademiaPermitidaSelector = ({ 
  className='',
  name,
  label,
  multiple=false,
  value,
  onChange 
})=> {

  const notification = useNotification()

  const { data: perfil={} } = useQuery({
    queryKey: ['personal', 'usuario'], 
    queryFn: ()=> getUsuario()
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar tu perfil', content: err })
        return {}
      })
  })

  const accesoCentral = canAccessCentral(perfil)
  const academias = perfil.centrosPermitidos || []
  const showDropdown = accesoCentral || academias.length > 1

  if (!showDropdown) return null
  
  return (
    <FormControl 
      className={`${className} ${css.main}`} 
      variant="standard"
      fullWidth
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        multiple={multiple}
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {accesoCentral && <MenuItem value=' '>Central (Sin centro)</MenuItem>}
        {academias.map(centro=> <MenuItem key={centro} value={centro}>{centro}</MenuItem> )}
      </Select>
    </FormControl>
  )
}

export default AcademiaPermitidaSelector