import { useNavigate } from "react-router-dom"
import PageDropdown from "../PageDropdown/PageDropdown"
import useFormacion from "../../hooks/useFormacion"
import css from "./FormacionHeader.module.css"

const FormacionHeader = ({ label }) => {

  const navigate = useNavigate()
  const { id, nombre } = useFormacion()

  const handleNavigate = (path) => () => {
    navigate(`/formaciones/${id}/${path}`)
  }

  const pages = [
    { name: 'Ficha', icon: 'person', onClick: handleNavigate('ficha') },
    { name: 'Asistencias', icon: 'auto_stories', onClick: handleNavigate('asistencias') },
    { name: 'Classfy', icon: 'all_inclusive', onClick: handleNavigate('classfy') },
    { name: 'Galería', icon: 'photo', onClick: handleNavigate('galeria') },
    { name: 'Cuadernillos', icon: 'book', onClick: handleNavigate('cuadernillos') },
  ]

  return (
    <header className={css.header}>
      <div className={css.headerIcon}>
        <i className='material-icons'>{pages.find(page => page.name === label).icon}</i>
      </div>
      <PageDropdown
        label={`${label} de ${nombre || 'formación'}`}
        pages={pages}
      />
    </header>
  )
}

export default FormacionHeader
